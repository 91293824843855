<template>
  <div>
    <!-- Sekcja z tłem w postaci auta-->
    <section
      class="has-background-grey-light has-text-white has-slider-one-background"
    >
      <div class="container">
        <div class="level">
          <div class="level-left link" @click="$router.go({ name: 'Home' })">
            <div class="level-item title">
              <b-image :src="require('@/assets/img/home/logo.png')"></b-image>
            </div>
          </div>
          <!--   <div class="level-right">
            <div class="level-item">
              <b-button
                type="is-link"
                tag="router-link"
                :to="{ name: 'ExploratorStartSearch' }"
              >
                Klient - wyszukiwarka usług
              </b-button>
            </div>
            <div class="level-item">
              <b-button
                type="is-link"
                tag="router-link"
                :to="{ name: 'CompanyOrders' }"
              >
                Firma - wirtualne biuro
              </b-button>
            </div>
          </div> -->
        </div>

        <nav class="columns is-centered has-text-centered">
          <div class="column is-2 link" @click="scrollTo('about')">
            APP CARS
          </div>
          <div class="column is-2 link" @click="scrollTo('system')">SYSTEM</div>
          <div class="column is-2 link" @click="scrollTo('cooperate')">
            WSPÓŁPRACA
          </div>
          <div class="column is-2 link" @click="scrollTo('partners')">
            PARTNERZY
          </div>
          <div class="column is-2 link" @click="scrollTo('contact')">
            KONTAKT
          </div>
        </nav>

        <hr />

        <div class="block py-6">
          <div class="py-6">
            <div class="columns is-centered">
              <div class="column is-8 has-text-centered">
                <p>APP CARS – WIRTUALNY ŚWIAT. PRAWDZIWA MOTORYZACJA.</p>
                <div class="block"></div>
                <p>
                  APPCARS to system oferujący nowoczesne rozwiązania oraz
                  profesjonalne narzędzia IT, których głównym zadaniem jest
                  usprawnienie komunikacji między partnerami biznesowymi
                  (firmami), a klientami indywidualnymi, w szeroko pojętej
                  branży motoryzacyjnej.
                </p>
              </div>
            </div>
            <div class="block py-1"></div>
            <div class="columns is-centered has-text-centered">
              <div class="column is-3">
                <b-button
                  type="is-primary"
                  tag="router-link"
                  :to="{ name: 'MainAuth' }"
                  >Konto biznesowe</b-button
                >
              </div>
              <div class="column is-3">
                <b-button
                  type="is-primary"
                  tag="router-link"
                  :to="{ name: 'MainAuth' }"
                  >Konto klienta</b-button
                >
              </div>
              <div class="column is-3">
                <b-button type="is-primary">Znajdź nas</b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Sekcja z informacja czym jest App Cars -->
    <section class="section" ref="about">
      <div class="container pt-3">
        <h1 class="title is-4 has-text-centered has-text-primary">
          Czym jest App Cars?
        </h1>
        <div class="block pt-3">
          Tworząc aplikację biznesową wiedzieliśmy, że nie możemy skupiać się
          tylko na jednej grupie odbiorców. Biznes to ścisła synergia firm oraz
          klientów, są oni jak jeden organizm połączony procesami i
          zależnościami. Skoro tak to funkcjonuje w rzeczywistości, w wirtualnym
          świecie nie może być inaczej.
        </div>

        <div class="block pb-5">
          Kompleksowe podejście do działania, to prawdziwy klucz do czerpania
          wzajemnych korzyści. Z tego właśnie powodu APPCARS posiada w swojej
          ofercie dwa rodzaje kont użytkownika. Każde z nich posiada odpowiednio
          dobrane rozwiązania oraz narzędzia adekwatne do potrzeb ich
          użytkowników.
        </div>

        <div class="columns has-text-centered py-6">
          <div class="column is-half right-border-primary px-6">
            <h1 class="title is-4 has-text-primary">
              KONTO BIZNESOWE - WIRTUALNE BIURO
            </h1>
            <p>
              w łatwy oraz intuicyjny sposób wspomaga pracę, marketing,
              zarządzanie firmą, usprawnia kontakt oraz obsługę klientów. Nasze
              rozwiązania dedykujemy głównie branży z zakresu auto detailingu,
              auto kosmetyki, myjni samochodowych, warsztatów samochodowych,
              stacji diagnostycznych oraz warsztatów blacharsko-lakierniczych.
              Aplikacja to także idealne rozwiązanie do tworzenia oraz obsługi
              sieci franczyzowej.
            </p>
          </div>
          <div class="column is-half left-border-primary px-6">
            <h1 class="title is-4 has-text-primary">
              KONTO KLIENTA - WIRTUALNY GARAŻ
            </h1>
            <p>
              To drugi rodzaj konta, którego oferta skierowana jest do klientów
              indywidualnych. Profesjonalny formularz kontaktowy usprawnia
              komunikację oraz wymianę informacji z usługodawcami. System
              pozwala nadzorować każdy etap zlecenia, umożliwia płatności
              bezgotówkowe, a także automatycznie dokumentuje i tworzy historię
              każdej zakończonej realizacji. Takie rozwiązanie jest idealne
              m.in. przy sprzedaży lub zakupie używanego samochodu.
            </p>
          </div>
        </div>

        <div class="block pt-5">
          <p>
            To tylko krótka zapowiedź tego co mamy Ci do zaoferowania, ale
            jesteśmy przekonani, że połączenie tak wyjątkowych rozwiązań to
            prawdziwa rewolucja dzisiejszej motoryzacji.
          </p>
        </div>

        <div class="block pt-5 has-text-centered">
          <span class="has-text-primary">APPCARS</span> - PODNOSZENIE
          WYDAJNOŚCI, POWIĘKSZANIE OSZCZĘDNOŚCI.
        </div>
      </div>
    </section>

    <!-- Trzeci element zawierający rozwijane elementy opisujące konto biznesowe oraz konto klienta -->
    <section class="" ref="system">
      <!-- Konto biznesowe - opis -->
      <b-collapse :open="true">
        <template #trigger>
          <div class="hero is-primary has-text-centered">
            KONTO BIZNESOWE
            <br />
            wirtualne biuro
          </div>
        </template>

        <div class="container">
          <div class="block is-flex is-justify-content-center">
            <figure class="image is-128x128">
              <img
                :src="require('@/assets/img/home/icons/business_account.png')"
                alt=""
              />
            </figure>
          </div>
          <div class="block pt-5">
            Do prawidłowego funkcjonowania biznesu niewątpliwie potrzebne są
            odpowiednie narzędzia i system, który będzie to wszystko nadzorował
            i zbierał w całość. Doskonale znamy wymagania oraz wyzwania obecnego
            rynku motoryzacji. Tempo zmian gospodarczych jest ogromne, dlatego
            aby zaoszczędzić Twój czas wszystkie niezbędne narzędzia i
            rozwiązania dajemy Ci w jednym konkretnym miejscu. Sprawdź bo może
            NASZA OFERTA to jest to czego od dawna szukasz!
          </div>
          <div class="block has-text-centered py-6">
            <h1 class="title is-4 has-text-primary">CO OFERJUMEY?</h1>
            <div class="columns is-centered">
              <div class="column is-4">
                <div class="is-flex is-justify-content-center">
                  <figure class="image is-64x64">
                    <img
                      :src="require('@/assets/img/home/icons/tools.png')"
                      alt=""
                    />
                  </figure>
                </div>
                <h1 class="subtitle is-6">NARZĘDZIA</h1>
                <div class="block">
                  Partner Biznesowy otrzymuje dostęp m.in do wirtualnego
                  kalendarza, pełnych statystyk firmy, możliwośći tworzenia bazy
                  danych, faktoringu, księgowości oraz marketingu internetowego.
                  Swoim klientom natomiast można zaoferować narzędzie dedykowane
                  specjalnie dla nich tzw. WIRTUALNY GARAŻ, który jest
                  kompatybilny kontem biznesowym.
                </div>
              </div>
              <div class="column is-4">
                <div class="is-flex is-justify-content-center">
                  <figure class="image is-64x64">
                    <img
                      :src="require('@/assets/img/home/icons/efficiency.png')"
                      alt=""
                    />
                  </figure>
                </div>
                <h1 class="subtitle is-6">WYDAJNOŚĆ</h1>
                <div class="block">
                  Wprowadzenie do firmy nowoczesnych i kompleksowych rozwiązań
                  znacznie podnosi jej wyniki. Narzędzie takie jak okno
                  statystyk pozwala na bieżąco monitorować wszystkie procesy
                  zachodzące w firmie, łącznie z finansami. Kontrola przychodów
                  oraz wydatków to bardzo istotna kwestia, w ciągu kilku sekund
                  możesz sprawdzić potrzebne informacje oraz planować firmowy
                  budżet
                </div>
              </div>
              <div class="column is-4">
                <div class="is-flex is-justify-content-center">
                  <figure class="image is-64x64">
                    <img
                      :src="require('@/assets/img/home/icons/saving.png')"
                      alt=""
                    />
                  </figure>
                </div>
                <h1 class="subtitle is-6">OSZCZĘDNOŚĆ</h1>
                <div class="block">
                  Lepsza komunikacja, zwiększenie wydajności oraz korzystanie z
                  odpowiednich narzędzi niewątpliwie przekłada się na
                  oszczędności finansowe, czasowe, ale także pozwala obniżyć
                  poziom streu. W przypadku prowadzenia własnej firmy te 3
                  elementy to jedne z najważniejszych czynników rozwoju
                  działalności oraz zadwolenia klientów.
                </div>
              </div>
            </div>
          </div>
          <div class="block py-6" ref="partners">
            <h1 class="title is-4 has-text-centered has-text-primary">
              DLA KOGO KONTO BIZNESOWE?
            </h1>
            <div class="columns is-multiline">
              <div
                class="column is-half"
                v-for="(trait, index) in audienceTraits"
                :key="index"
              >
                <div class="block">
                  <b-icon icon="check-box-outline" type="is-primary"></b-icon>
                  {{ trait }}
                </div>
              </div>
            </div>
          </div>

          <div class="block py-6">
            <h1 class="title is-4 has-text-primary has-text-centered">
              WIRTUALNE BIURO TO:
            </h1>
            <div class="columns is-multiline">
              <div
                class="column is-3"
                v-for="(virtualOfficeFeature, index) in virtualOffice"
                :key="index"
              >
                <div class="media">
                  <figure class="media-left">
                    <p class="image is-64x64">
                      <img
                        :src="
                          require(`@/assets/img/home/icons/numbers/number${
                            index + 1
                          }.png`)
                        "
                        alt=""
                      />
                    </p>
                  </figure>
                  <div class="media-content">
                    <div class="content">
                      {{ virtualOfficeFeature }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-collapse>

      <div class="hero has-text-centered py-6 my-6 has-slider-two-background">
        <h1 class="title is-4 has-text-white">
          APPCARS - WIRTUALNY ŚWIAT. PRAWDZIWY BIZNES
        </h1>
        <div class="columns is-centered" ref="cooperate">
          <div class="column is-4">
            <b-button type="is-primary">ZAŁÓŻ DARMOWE KONTO BIZNES</b-button>
          </div>
        </div>
      </div>
      <!-- Konto klienta - opis -->
      <b-collapse :open="true">
        <template #trigger>
          <div class="hero is-primary has-text-centered">
            KONTO KLIENTA
            <br />
            wirtualny garaż
          </div>
        </template>

        <div class="container">
          <div class="block is-flex is-justify-content-center">
            <figure class="image is-128x128">
              <img
                :src="require('@/assets/img/home/icons/client.png')"
                alt=""
              />
            </figure>
          </div>
          <div class="block pt-5">
            „Biznes bez klientów to nie biznes”. W myśl tej zasady stworzysliśmy
            rozwiązanie, które dedykujemy osobom korzystającym z usług naszych
            partnerów biznesowych, lub zwyczajnie pragnących „kontrolować”
            sprawy związane ze swoim samochodem w prosty i przyjemny sposób oraz
            być na bieżąco z nowinkami oraz aktualnymi promocjami. Większość z
            nas jest mocno przywiązana do swoich samochodów, ale czy wiemy o
            nich wszystko? Czy wiemy gdzie mamy faktury za każdą naprawę? Czy
            pamiętamy o przeglądach oraz ubezpieczeniach? A może czas na naprawę
            i zakup części w korzystnych cenach? Na pomoc przychodzi wyjątkowe
            narzędzie – WIRTUALNY GARAŻ!
          </div>

          <div class="block py-6 has-text-centered">
            <h1 class="title is-4 has-text-primary">CO OFERJUMEY?</h1>
            <div class="columns is-centered">
              <div class="column is-4">
                <div class="is-flex is-justify-content-center">
                  <figure class="image is-64x64">
                    <img
                      :src="require('@/assets/img/home/icons/tools.png')"
                      alt=""
                    />
                  </figure>
                </div>
                <h1 class="subtitle is-6">NARZĘDZIA</h1>
                <div class="block">
                  Profesjonalny garaż to przede wszystkim odpowiednie narzędzia.
                  Panel klienta to spory wachlarz możliwości oraz rozwiązań z
                  dziedziny motoryzacji. Dzięki niemu możesz rezerwować usługi
                  po przez prosty i intuicyjny formularz kontaktowy, kontrolować
                  wszystkie wydatki po przez okno statystyk, tworzyć histrorię
                  samochodu, ustawiać przypomnienia (ubezpieczenie, przegląd
                  itp.) korzystać z promocji oraz dedykowanych ofert.
                </div>
              </div>
              <div class="column is-4">
                <div class="is-flex is-justify-content-center">
                  <figure class="image is-64x64">
                    <img
                      :src="
                        require('@/assets/img/home/icons/communication.png')
                      "
                      alt=""
                    />
                  </figure>
                </div>
                <h1 class="subtitle is-6">KOMUNIKACJA</h1>
                <div class="block">
                  Rozwiązania które oferujemy nie powstały z przypadku, są
                  odpowiedzią na codzienne problemy oraz wyzwania milionów
                  właścicieli samochodów. Usprawnienie komunikacji między
                  klientami, a firmami usługowymi, nowoczesne narzędzia IT to
                  jednak nie wszystko. Jesteśmy przekonani, że automatyzacja
                  procesów związanych z użytkowaniem samochodu przełoży się na
                  oszczędności zarówno czasu jak i pieniędzy.
                </div>
              </div>
              <div class="column is-4">
                <div class="is-flex is-justify-content-center">
                  <figure class="image is-64x64">
                    <img
                      :src="require('@/assets/img/home/icons/solutions.png')"
                      alt=""
                    />
                  </figure>
                </div>
                <h1 class="subtitle is-6">ROZWIĄZANIA</h1>
                <div class="block">
                  System, który oferujemy powstał po to, aby w jak największym
                  stopniu udoskonalić wymianę informacji między klientami, a
                  firmami wykonującymi usługi. Aplikacja pozwala na bieżąco
                  monitorować etapy każdej pracy oraz przypomina o
                  najważniejszych czynnościach związanych z codziennym
                  użytkowaniem samochodu.
                </div>
              </div>
            </div>
          </div>

          <div class="block py-6">
            <h1 class="title is-4 has-text-centered has-text-primary">
              WIRTUALNY GARAŻ TO:
            </h1>
            <div class="columns is-multiline">
              <div
                class="column is-3"
                v-for="(virtualGarageFeature, index) in virtualGarage"
                :key="index"
              >
                <div class="media">
                  <figure class="media-left">
                    <p class="image is-64x64">
                      <img
                        :src="
                          require(`@/assets/img/home/icons/numbers/number${
                            index + 1
                          }.png`)
                        "
                        alt=""
                      />
                    </p>
                  </figure>
                  <div class="media-content">
                    {{ virtualGarageFeature }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-collapse>
    </section>

    <div class="hero has-text-centered py-6 my-6 has-slider-three-background">
      <h1 class="title is-4 has-text-white">
        WIRTUALNY GARAŻ. REALNE KORZYŚCI.
      </h1>
      <div class="columns is-centered">
        <div class="column is-4">
          <b-button type="is-primary">ZAŁÓŻ DARMOWE KONTO KLIENTA</b-button>
        </div>
      </div>
    </div>

    <!-- Sekcja znajdź nas -->
<!--     <section class="" ref="">
      <div class="hero has-text-centered is-primary">
        ZNAJDŹ NAS
        <br />
        mapa partnerów
      </div>

      <div class="container">
        <div class="block is-flex is-justify-content-center">
          <figure class="image is-128x128">
            <img :src="require('@/assets/img/home/icons/find_us.png')" alt="" />
          </figure>
        </div>
        <div class="columns is-multiline">
          <div class="column is-3">
            <b-field>
              <b-input placeholder="wpisz, czego szukasz"></b-input>
            </b-field>
          </div>
          <div class="column is-12 is-primary">MIEJSCE NA MAPE</div>
        </div>
      </div>
    </section> -->

    <!-- Sekcja skontaktuj sie z nami -->
    <section
      class="block has-slider-four-background has-text-white mb-0"
      ref="contact"
    >
      <div class="hero has-text-centered is-primary">
        <div>SKONTAKTUJ SIĘ Z NAMI</div>
        formularz kontaktowy
      </div>

      <div class="container block mt-5 mb-2">
        <div class="columns">
          <div class="column is-2">Imię i nazwisko</div>
          <div class="column is-4">
            <b-field>
              <b-input v-model="name"></b-input>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column is-2">Adres e-mail</div>
          <div class="column is-4">
            <b-field>
              <b-input type="email" v-model="email"></b-input>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column is-2">Temat wiadomości</div>
          <div class="column is-4">
            <b-field>
              <b-input v-model="subject"></b-input>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column is-2">Wiadomośc</div>
          <div class="column is-8">
            <b-field>
              <b-input type="textarea" v-model="message"></b-input>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column is-4 is-offset-6 has-text-right">
            <b-button class="is-primary" @click="askQuestion()">Wyślij</b-button>
          </div>
        </div>
      </div>
    </section>
    <page-footer></page-footer>
    <!-- Sekcja - stopka
    <section class="section has-background-dark has-text-white">
      <div class="container">
        <div class="columns">
          <div class="column is-2 pl-0">
            <b-image :src="require('@/assets/img/home/logo.png')"></b-image>
          </div>
          <div class="column is-3 has-text-centered right-border-primary">
            DANE KONTAKTOWE
          </div>
          <div class="column is-3 left-border-primary pl-6">
            <div class="pb-2">SYSTEM</div>
            <div class="py-2">WSPÓŁPRACA</div>
            <div class="py-2">PARTNERZY</div>
            <div class="py-2">KONTAKT</div>
          </div>
          <div class="column is-4">
            <b-image :src="require('@/assets/img/banners/b1.png')"></b-image>
          </div>
        </div>
      </div>
    </section> -->
  </div>
</template>

<script>
import Footer from "./../components/common/Footer";

export default {
  components: {
    "page-footer": Footer,
  },
  data() {
    return {
      audienceTraits: [
        "Dla ceniących najwyższą jakość usług",
        "Dla szanujących czas swój oraz swoich klientów",
        "Dla dbających o wzorowy kontakt i relacje z klientem",
        "Dla poszukujących innowacyjności",
        "Dla profesjonalistów",
        "Dla pragnących być krok dalej niż konkurencja",
        "Dla tych, którzy chcą automatyzacji i monitoringu procesów",
        "Dla tych, którzy chcą czegoś więcej",
      ],

      virtualOffice: [
        "Spersonalizowany panel obsługi z indywidualną konfiguracją (cennik, oferta itp.)",
        "Wirtualny kalendarz oraz formularz zamówień",
        "Tworzenie bazy danych (klienci, samochody itp.)",
        "Zbiorcze kampanie reklamowe (sms, e-mail)",
        "Zarządzanie zleceniami, klientami, księgowością oraz faktoringiem",
        "Hurtownia online + dedykowane oferty specjalne",
        "Serwis ogłoszeniowy + forum motoryzacyjne",
        "Ciągły rozwój systemu",
      ],

      virtualGarage: [
        "Spersonalizowany panel klienta/samochodu",
        "Rezerwacja usług online (myjnia, mechanik, przegląd itp.)",
        "Tworzenie historii samochodu (naprawy, przeglądy, mycie)",
        "Przypomnienia SMS, E-MAIL (ubezpieczenie, przegląd, 3 mycie itp.)",
        "Sklep online ze stałymi rabatami",
        "Statystyki wykonanych usług, finanse (myjnia, naprawy itp.)",
        "Dedykowane oferty specjalne (ubezpieczenia, części itp.)",
        "Serwis ogłoszeniowy + forum motoryzacyjne",
      ],
      name: '',
      email: '',
      subject: '',
      message: ''
    };
  },

  methods: {
    scrollTo(elementRef) {
      const targetElem = this.$refs[elementRef];

      if (targetElem) {
        targetElem.scrollIntoView({ behavior: "smooth" });
      }
    },

    askQuestion: function(){
      if(this.name != '' && this.email != '' && this.subject != '' && this.message != ''){
        const payload  = {
          name: this.name,
          email: this.email,
          subject: this.subject,
          message: this.message
        }

        this.$store.dispatch("auth/question", payload).then((response) => {
          if(response == 200)                this.$buefy.notification.open({
                    message: 'Zapytanie zostało wysłane',
                    type: 'is-primary'
                })
                else this.$buefy.notification.open({
                    message: 'Nie udało się wysłać zapytania.',
                    type: 'is-dark'
                })
        }).catch(err => {
            console.error(err)
            this.$buefy.notification.open({
                    message: 'Nie udało się wysłać zapytania.',
                    type: 'is-dark'
                })
        })
      }
    }
  },
};
</script>

<style lang="scss">
.has-slider-one-background {
  background-image: url("../assets/img/home/sliders/slider1.jpg");
  background-size: cover;
}

.has-slider-two-background {
  background-image: url("../assets/img/home/sliders/slider2.jpg");
  background-size: cover;
}

.has-slider-three-background {
  background-image: url("../assets/img/home/sliders/slider3.jpg");
  background-size: cover;
}

.has-slider-four-background {
  background-image: url("../assets/img/home/sliders/slider4.jpg");
  background-size: cover;
}

.left-border-primary {
  border-left: 0.1rem solid $primary;
}

.right-border-primary {
  border-right: 0.1rem solid $primary;
}

.field > .control > input,
.field > .control > textarea {
  background-color: rgba(255, 255, 255, 0.5);
}

div.link {
  &:hover {
    cursor: pointer;
  }
}

.hero {
  font-size: 22pt;
  margin-bottom:1rem!important;
  margin-top:1rem!important;
}
</style>
